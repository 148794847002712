@import url('https://fonts.googleapis.com/css?family=Abril+Fatface|Lato');

$big: 'Abril Fatface', serif;
$body: 'Lato', sans-serif;

.pnf {
  background-image: url("./bg5.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 90vh;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .top {
    margin-top: 30px;
    animation: float 2s ease-out infinite;

    h1 {
      font-family: $big;
      color: white;
      text-align: center;
      font-size: 9em;
      margin: 0;
      text-shadow: -1px 0 gray, 0 1px gray, 1px 0 gray, 0 -1px gray;
    }

    h3 {
      font-family: $body;
      font-size: 2em;
      text-transform: uppercase;
      text-align: center;
      color: rgba(232, 232, 232, 0.656);
      margin-top: -20px;
      font-weight: 900;
    }
  }

  .bottom {
    margin-top: 50px;

    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;

      .btn {
        background: rgba(255, 255, 255, 0);
        padding: 15px 20px;
        margin: 5px;
        color: #ffffff;
        font-family: $body;
        font-size: 1em;
        text-transform: uppercase;
        letter-spacing: 1px;
        border: 2px solid white;

        &:hover {
          background: gray;
          transition: all .4s ease-out;
        }
      }
    }


  }
}



@keyframes scale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes float {
  50% {
    transform: translateY(15px);
  }
}