@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&family=Ubuntu:wght@400;500&display=swap');


.EventsDetails{
    margin:0;
    padding:0;
    box-sizing: border-box;
    /* background: radial-gradient(circle, rgba(0,11,64,1) 0%, rgba(0,0,0,1) 100%); */
    /* background-color: #04031c; */
    background-color: var(--bgColor1);

}

.Events_container{
    display: grid;
    height: 100%;
    /* overflow-y: hidden; */
    overflow-x: hidden;
    grid-template-columns: 50% 50%;
    padding: 2% 7%;
    /* margin-bottom: 10vh; */
    /* background-color: #111317; */
    /* background: radial-gradient(circle, rgba(0,11,64,1) 0%, rgba(0,0,0,1) 100%); */
    color: #ebe6d0;
    font-family: 'Montserrat', sans-serif;
}

.Event_container_left{
    display: flex;
    justify-content: flex-start;
    padding-right: 8%;
    height: 100%;

    flex-direction: column;
    align-items: center;
}

.Events_img{
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-width: 600px;
    min-height: 300px;

}

.Events_img img{
   width: 400px;
   height: 500px;
   object-fit: 100% 100%;
}

.Event_container_right{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 0 5% 0;
    overflow: hidden;
    height: fit-content;
    width: 100%;
    font-size: 14px;

    align-items: center;
}

hr{
    width: 70%;
    margin: 2rem auto 2rem;
    border: 1px solid;
}

.Event_title{
    
    font-size: 30px;
    font-weight: 700;
    margin:  0 0 2rem 0;
    line-height: 36px;

}

.Event_desc{
    text-align: justify;
    margin-bottom: 2rem;
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
}

.Event_register_button{
    cursor: pointer;
    bottom: 0;
    border: 2px solid black;
    border-radius: 30px;
    font-weight: 700;
    width: 70%;
    margin: 2rem auto 0 auto;
    height: 3rem;
    position: relative;
    top: 10%;
    background:-webkit-linear-gradient(0deg,transparent 50%, #ebe6d0 50%);
    transition: 0.8s;
    background-size: 800px 50px;
    border:4px solid #ebe6d0;
    color: #ebe6d0;
    font-size: 1.2rem;
}

.Event_register_button:hover{
  cursor: pointer;
  background-position: 400px 0;
  color: black;
}

.Event_register_button_anchor{
    width:100%;
    text-align: center;
}

.detail_images{
    width: 100%;
    margin-top: 1rem;
    height: fit-content;
}

.register_details{
    width: 50%;
}

.Event_register_details{
    width: 50%; 
    display: grid;
    grid-template-columns: 50% 50%;
    row-gap: 1rem;
    margin-bottom: 2rem;
}
.events-rules {
	margin-top: 2rem;
        border-radius: 2px;
        background: rgba(172, 235, 246, 1);
        color: #000;
        cursor: pointer;
        padding: 0.2rem 1rem;
        border: 1px solid rgba(172, 235, 246, 1);
        margin-top: 20px;
    
        font-family: Poppins;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 2rem;
        letter-spacing: 0em;
}
.events-rules:hover{
    box-shadow: 5px 2.5px 2.5px rgb(157, 152, 193);
}
.events-rules-anchor{
    display: flex;
    justify-content: center;
    align-items: center;
}
.days_icon
,.location_icon,.time_icon{
    margin-right: 1rem;
    overflow-x: visible;
}

.Event_date,.Event_location{
    display: inline;
    display: flex;
    align-items: center;
    padding: 1%;
    overflow-x:visible;
   
}

.Event_location{
    margin-right: 2rem;
}

.join_disco {
    width: 15rem;
    background: linear-gradient(90deg, #9796f0 0%, #fbc7d4 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:3rem;
    height: 40px;
    cursor: pointer;
    color: #091335;
  }
  .join_dis_anchor{
    display: flex;
    justify-content: center;
    
  }
  .join_disco i {
    margin-right: 10px;
  }
  /* .fa-discord{
    font-family: 'orbitron';
  } */
  
  /* .join_dis {
    margin-bottom: 29px;
    margin-top: 19px;
  } */




@media only screen and (max-width:1080px){
    .apply_button{
        width: 312px!important;
    }
    .Events_container{
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        
     
       
    }
    .events-rules {
        margin-top: 2rem;
        border-radius: 2px;
        background: rgba(172, 235, 246, 1);
        color: #000;
        cursor: pointer;
        padding: 0.2rem 1rem;
        border: 1px solid rgba(172, 235, 246, 1);
        margin-top: 20px;
    
        font-family: Poppins;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 2rem;
        letter-spacing: 0em;
    }

    .Event_container_left{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        object-fit: cover;
        width: 85vw;
        padding-right: 0;;
        /* margin: auto; */
    }

    .Events_img{
        object-fit: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80vw;
        
    }

    .Events_img img{
        object-fit: cover;
      height: fit-content;
        width: 82vw;
    }

    .Event_container_right{
        padding: 2rem 0;
        width: 85vw;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;

    }

    .Event_register_details{
        width: 85vw;
        margin-bottom: 2rem;
    }

    .Event_register_button{
        color: #000;
        background-color: #ebe6d0;
        width: 100%;
        font-size: 1.2rem;
    }
    .Event_register_button_anchor{
        width:100%;
    }

}