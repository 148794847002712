@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
/* font-family: 'Bebas Neue', cursive; */

@import url('https://fonts.googleapis.com/css2?family=Genos:ital,wght@0,200;0,300;0,400;0,700;1,400;1,700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@700&display=swap");

/* font-family: 'Genos', sans-serif; */
@font-face {
    font-family: Stargaze;
    src: url("./assets/fonts/stargaze_reg.woff");
}

* {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    box-sizing: border-box;
   
}
:root{
    --bgColor1:#091335;
    --fontfamily:'Orbitron', sans-serif;
}
html{
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;

    scroll-behavior: smooth;
}

a {
    text-decoration: none;
    color: inherit;
}

li {
    list-style: none;
}

html{
    scroll-behavior: smooth;
}