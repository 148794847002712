/* @import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700;900&display=swap'); */

.page_body{
    height : 60vh;
    background-color: transparent;
   
    
}
.page{
    width : 100%;
    /* background-color:white; */
    /* background-color: #04031c;  */
    background-image: linear-gradient(to bottom,rgb(4, 3, 28,0),var(--bgColor1)),url("/src/assets/images/pagename-bg2.png");
    height : 50vh;
    position : relative;
    background-size: cover;
;
}

.page_heading{
    width : 100%;
    height : fit-content;
    color: white;
    display : flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    position: absolute;
    top: 35%;
}

.page_heading h5{
    font-style: italic;
    overflow: hidden;
    height: 25%;
    font-size: 15px;
}

.page h1{
    font-size : 6rem;
    font-family: 'Orbitron', sans-serif;
    padding: 0.6rem 0 1.5rem;
    font-weight: 700;
    letter-spacing: 7px;
    text-align: center;
    overflow-y: hidden;
}


@media screen and (max-width: 650px) {
    .page{
        background-position: center;
        height:45vh;
        
    }
    .page_heading{
        align-items: center;
        position: absolute;
        top: 40%;
    }
    .page_body{
        height: 45vh;
    }
    .page h1{
        font-size : 3rem;
    }
}