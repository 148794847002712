@font-face {
  font-family: "ESL Legend";
  src: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Light.eot);
  src: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Light.eot#iefix) format("embedded-opentype"),
    url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Light.woff2) format("woff2"),
    url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Light.woff) format("woff"),
    url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Light.ttf) format("truetype"),
    url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Light.svg#eicon) format("svg");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "ESL Legend";
  src: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Regular.eot);
  src: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Regular.eot#iefix) format("embedded-opentype"),
    url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Regular.woff2) format("woff2"),
    url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Regular.woff) format("woff"),
    url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Regular.ttf) format("truetype"),
    url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Regular.svg#eicon) format("svg");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "ESL Legend";
  src: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Bold.eot);
  src: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Bold.eot#iefix) format("embedded-opentype"),
    url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Bold.woff2) format("woff2"),
    url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Bold.woff) format("woff"),
    url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Bold.ttf) format("truetype"),
    url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Bold.svg#eicon) format("svg");
  font-style: normal;
  font-weight: bold;
}

:root {
  --color1: #1c97d4;
}

.eventTitle {
  text-transform: uppercase;
  font-family: "genos";
  font-size: 27px;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  text-align: center;
} 

.daywise_buttons {
  margin: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  height: fit-content;
}

.day1_button,
.day2_button {
  background: transparent;
  padding: 0;
  font-family: "Genos";
  border-color: transparent;
  color: white;
  height: 42px;
  width: 70px;
  font-size: 1.5rem;
  line-height: 2rem;
  overflow: hidden;
  margin: 0 2rem;
  cursor: pointer;
}

.day1_button:hover {
  border-bottom: 1px solid var(--color1);
  color: var(--color1);
}

.day2_button:hover {
  border-bottom: 1px solid var(--color1);
  color: var(--color1);
}

.ag-format-container {
  width: 1160px;
  margin: 0 auto;
  position: relative;
}

.ag-timeline-block {
  padding: 100px 0;
  overflow-x: hidden;
}

.ag-timeline_line {
  width: 2px;
  background-color: #393935;
  position: absolute;
  top: 2px;
  left: 50%;
  bottom: 0;

  overflow: hidden;

  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.fa-clock,
.fa-location-dot {
  color: var(--color1);
}

.ag-timeline_item {
  margin: 0 0 50px;
  position: relative;
}

.ag-timeline_item:nth-child(2n) {
  text-align: right;
}

.ag-timeline_line-progress {
  width: 100%;
  height: 20%;
  background-color: var(--color1);
}

.ag-timeline-card_box {
  padding: 0 0 20px 50%;
}

.ag-timeline_item:nth-child(2n) .ag-timeline-card_box {
  padding: 0 50% 20px 0;
}

.ag-timeline-card_point-box {
  display: inline-block;
  margin: 0 14px 0 -28px;
}

.ag-timeline_item:nth-child(2n) .ag-timeline-card_point-box {
  margin: 0 -28px 0 14px;
}

.ag-timeline-card_point {
  height: 50px;
  line-height: 50px;
  width: 50px;
  border: 3px solid var(--color1);
  background-color: #1d1d1b;

  text-align: center;
  font-family: 'Genos';
  font-size: 20px;
  color: #fff;
  overflow: hidden;

  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

.js-ag-active .ag-timeline-card_point {
  color: white;
  background-color: var(--color1);
  overflow: hidden;
}

.ag-timeline-card_item {
  display: inline-block;
  width: 45%;
  margin: -77px 0 0;
  background-color: transparent;
  padding: 1rem;
  opacity: 0;

  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;

  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
  -o-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);

  -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
  -moz-transition: -moz-transform 0.5s, opacity 0.5s;
  -o-transition: -o-transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s;

  position: relative;
}

.ag-timeline_item:nth-child(2n + 1) .ag-timeline-card_item {
  -webkit-transform: translateX(-200%);
  -moz-transform: translateX(-200%);
  -ms-transform: translateX(-200%);
  -o-transform: translateX(-200%);
  transform: translateX(-200%);
}

.ag-timeline_item:nth-child(2n) .ag-timeline-card_item {
  -webkit-transform: translateX(200%);
  -moz-transform: translateX(200%);
  -ms-transform: translateX(200%);
  -o-transform: translateX(200%);
  transform: translateX(200%);
}

.js-ag-active.ag-timeline_item:nth-child(2n + 1) .ag-timeline-card_item,
.js-ag-active.ag-timeline_item:nth-child(2n) .ag-timeline-card_item {
  opacity: 1;

  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.ag-timeline-card_arrow {
  height: 25px;
  width: 15px;
  margin-top: 20px;
  background-color: white;

  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;

  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ag-timeline_item:nth-child(2n + 1) .ag-timeline-card_arrow {
  margin-left: calc(-18px / 2);
  margin-right: calc(-18px / 2);
}

.ag-timeline_item:nth-child(2n) .ag-timeline-card_arrow {
  margin-left: -10px;

  right: auto;
  left: 0;
}

.ag-timeline-card_inner {
  position: relative;
  border: 1px solid white;
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: white;
  border-radius: 1.5rem;
}

.ag-timeline-card_img {
  width: 100%;
}

.ag-timeline-card_img-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  overflow: hidden;
  position: relative;
  border-bottom-left-radius: 4rem;
  border-bottom-right-radius: 4rem;
  box-shadow: 0px 4px 30px rgb(176 196 222 / 35%);

}

.eventTitle {
  text-transform: uppercase;
  font-family: "genos";
  font-size:  27px;
  letter-spacing: 0px;
}

.ag-timeline-card_img-box img {
  position: absolute;
  width: 100%;
  object-fit: cover;
  opacity: 0.6;
  z-index: -2;
}

.ag-timeline-card_info {
  padding: 0px 15px;
  line-height: 1.2;
  font-size: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card_info_tagline {
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.card_info_data {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
}

.card_info_data h6 {
  margin-left: 10px;
  font-family: 'Genos';
  width:50%;
  font-size: 20px;
  text-transform: uppercase;
}

.card_info_data i {
  margin-left: 20px;
  overflow: visible;
}

.ag-timeline-card_title {
  display: none;
  margin: 10px 0 0;

  font-family: "ESL Legend", sans-serif;
  font-weight: bold;
  font-size: 28px;
  color: var(--color1);
}

@media only screen and (max-width: 979px) {
  .ag-timeline_line {
    left: 30px;
  }

  .ag-timeline-card_item {
    width: 80%;
    padding: 1rem;
  }



  .ag-timeline-card_info {
    padding: 20px 15px;
  }


  .ag-timeline_item:nth-child(2n) {
    text-align: left;
  }

  .ag-timeline-card_box,
  .ag-timeline_item:nth-child(2n) .ag-timeline-card_box {
    padding: 0 0 20px;
  }

  .ag-timeline-card_meta-box {
    display: none;
  }

  .ag-timeline-card_point-box,
  .ag-timeline_item:nth-child(2n) .ag-timeline-card_point-box {
    margin: 0 0 0 8px;
  }

  .ag-timeline-card_point {
    height: 40px;
    line-height: 40px;
    width: 40px;
  }

  .ag-timeline-card_item {
    width: auto;
    margin: -65px 0 0 75px;
  }

  .ag-timeline_item:nth-child(2n + 1) .ag-timeline-card_item,
  .ag-timeline_item:nth-child(2n) .ag-timeline-card_item {
    -webkit-transform: translateX(200%);
    -moz-transform: translateX(200%);
    -ms-transform: translateX(200%);
    -o-transform: translateX(200%);
    transform: translateX(200%);
  }

  .ag-timeline_item:nth-child(2n + 1) .ag-timeline-card_arrow {
    right: auto;
    left: 0;
  }

  .ag-timeline-card_title {
    display: block;
  }

  .ag-timeline-card_arrow {
    margin-top: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .ag-timeline-block {
    padding: 50px 0;
  }

  .ag-format-container {
    width: 96%;
  }

  .ag-timeline-card_item {
    width: 80%;
    padding: 1rem;
  }

  .ag-timeline-card_info {
    padding: 20px 0px;
  }

  .ag-timeline-card_info {
    padding: 20px 15px;
  }



  .ag-timeline-card_img {
    height: auto;
    width: auto;
    // width: 80%;
  }
}

@media only screen and (max-width: 639px) {
  .ag-timeline_title {
    font-size: 60px;
  }

  .ag-timeline-card_item {
    width: 80%;
    padding: 1rem;
  }

  .ag-timeline-card_info {
    padding: 10px 0px;
  }

  .ag-timeline-card_desc {
    font-size: 20px;

  }
}

.download_buttons {
  display: flex;
  width: 80vw;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.reg_button {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  padding: 5px;
  border: 1px solid white;
  border-radius: 15px;


  h1 {
    font-size: 2rem;
    font-family: 'Bebas Neue';
    margin: 0 10px;
  }

  img {
    margin: 0 10px;
  }


}

.reg_button:hover {
  cursor: pointer;
  box-shadow: 5px 4px 8px 0px rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 5px 4px 8px 0px rgba(255, 255, 255, 0.5);
  -moz-box-shadow: 5px 4px 8px 0px rgba(255, 255, 255, 0.5);
  // background-image: linear-gradient(#0F5BB2,#177BF2,#3E97FF,#63ACFF,#709CCC);
}


@media screen and (max-width: 1080px) {
  .download_buttons {
    flex-direction: column;

    .reg_button {
      h1 {
        font-size: 20px;
        font-family : 'Genos';
        text-align: center;
      }
    }
  }

}

@media only screen and (max-width: 479px) {
  .ag-timeline-card_item {
    width: 80%;
    padding: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 979px) {
  .ag-format-container {
    width: 750px;
  }

  .ag-timeline-card_item {
    width: 80%;
    padding: 1rem;
  }

}

@media (min-width: 980px) and (max-width: 1161px) {
  .ag-format-container {
    width: 960px;
  }

  .ag-timeline-card_item {
    width: 80%;
    padding: 1rem;
  }
}