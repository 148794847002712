@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&family=Ubuntu:wght@400;500&display=swap');

// * {
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
//   }
  
//   body {
//     font-family: sans-serif;
//     background: #eee;
//   }
  
//   header {
//     display: flex;
//     justify-content: center;
//     align: center;
//     padding: 15px;
//     background: #3c3c3c;
//     border-bottom: 3px solid #56e388;
//   }
  
//   header h1 {
//     color: #eee;
//     font-size: 28px;
//     font-weight: 700;
//     text-transform: uppercase;
//   }
  
  .faqs {
    width: 100%;
    // max-width: 768px;
    margin: 0 auto;
    padding: 15px;
    // color: #ebe6d0;
    color: white;
    // background-color: black;
    // background: radial-gradient(circle, rgba(0,11,64,1) 0%, rgba(0,0,0,1) 100%);
    font-family: 'Montserrat', sans-serif;

  }
  
  .faq_heading{
    font-size: 100px;
    font-family: 'Montserrat', sans-serif;
    text-align: left;

  }
  .faqs .faq {
    margin: 15px;
    padding: 15px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    // background-color: black;
    background: radial-gradient(circle, transparent 0%, rgba(52,48,71,1) 100%);

    // border-bottom: 1px solid #2f302d;
    cursor: pointer;
  }
  
  .faqs .faq .faq-question {
    position: relative;
    font-size: 20px;
    padding-right: 80px;
    transition: all 0.4s ease;
    overflow: hidden;
    font-size: 21px;
    letter-spacing: 0.1rem;
    // opacity: 50%;
    font-weight: 600;
    color : rgba(255, 255, 255, 0.6);
  }
  
  .faqs .faq .faq-question::after {
    content: "+";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    transition: all 0.2s ease;
    color:white;
  }
  
  .faqs .faq .faq-answer {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease;
  }
  
  .faqs .faq.open .faq-question {
    margin-bottom: 15px;
    opacity: 100%;
  }
  
  .faqs .faq.open .faq-question::after {
    content: "-";
    color:white;
  }
  
  .faqs .faq.open .faq-question{
    color:#fff
  }

  .faqs .faq.open .faq-answer {
    max-height: 1000px;
    opacity: 100%;
    padding-top: 0.75rem;
    // color: hsla(49,40%,87%,.5);
    color: white;
  }
  

  @media only screen and (max-width:576px){
    .faq_heading{
        font-size: 71px;
      }

      .faqs .faq .faq-question {
        font-size: 15px;
      }
  }