@import url('https://fonts.googleapis.com/css?family=Mr+Dafoe');

.orgteam-section{
  width: 100wh;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, Avenir Next, Avenir, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  color: #fff;
  font-size: 50px;
}
.orgteam-container{
  overflow-x: none;
  font-family: -apple-system, BlinkMacSystemFont, Avenir Next, Avenir, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  justify-content: center;
  // height: 100vh;
  background: transparent;
  max-width: 100wh;
  margin: auto;
}

.orgteam-grid-row {
  overflow-x: visible;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 280px));
  grid-gap: 150px 60px;
  justify-content: center;
  align-items: baseline;
  margin-top: 5rem;
  margin-bottom: 8rem;
}

.orgteam-frame {
  overflow-x: visible;
  position: relative;
  z-index: 1;
  transition:0.8s all ease;
  &:hover {
    &:after {
      transform:rotate(-3deg);    
    }
    &:before {
      transform:rotate(15deg);
    }
    & img {
      transform:rotate(6deg)
    }
  }
&:after {
  content:'';
  height:255px;
  width:285px;
  display:block;
  position: absolute;
  top:0;
  left:-2%;
  z-index: -1;
  transition:0.4s all ease;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 90 90'%3E%3Ccircle fill='%23ffffff' fill-opacity='0.8' cx='45' cy='45' r='10'/%3E%3Cg fill='%23ffffff' fill-opacity='0.8'%3E%3Ccircle cx='0' cy='90' r='10'/%3E%3Ccircle cx='90' cy='90' r='10'/%3E%3Ccircle cx='90' cy='0' r='10'/%3E%3Ccircle cx='0' cy='0' r='10'/%3E%3C/g%3E%3C/svg%3E");
}
&:before {
  content:'';
  height:102%;
  width:95%;
  display:block;
  position: absolute;
  top:-5px;
  left:9%;
  z-index: -1;
  transition:0.6s all ease;
  background: #B1CFE4;
  transform:rotate(10deg);
}
    img {
    transform:rotate(5deg);
    transition:0.2s all ease;
    border:4px double #E77470;
    height:250px;
    width:250px;
    overflow-x: visible;
    object-fit:cover;
    overflow: hidden;
    }
}

[data-tooltip][tabindex="0"] {
  display: inline-block;
  position: relative;
  color: #fbfbfe;
  font-family: "Genos", cursive;
  font-size:24px;
  overflow-x: visible;
  
  &::after {
    display: none;
    position: absolute;
    bottom: 110%;
    left: 50%;
    padding: 10px;
    max-width: 300px;
    transform: translateX(-50%);
    border: 0.5px solid rgb(177, 207, 228,0.6);
    border-radius: 1.2rem;
    // You can also use a fixed width and ommit the white-sapce.
    white-space: nowrap;
    // background-color: #fff;
    background-image: radial-gradient(circle, rgb(9, 19, 53,0.4) 50%, rgb(177, 207, 228,0.4) 100%);
    // Make sure tooltips don't block each others trigger.
    pointer-events: none;
    content: attr(data-tooltip);
  }
  
  // Create a neat little arrow
  // &::before {
  //   display: none;
  //   position: absolute;
  //   bottom: 110%;
  //   left: 50%;
  //   z-index: 2;
  //   transform: translate(-50%, 50%) rotate(45deg);
  //   width: 6px;
  //   height: 6px;
  //   border: solid #E77470;
  //   border-width: 0 1px 1px 0;
  //   background-color: #fff;
  //   content: '';
  // }
  
  // Activate tooltip
  &:focus,
  &:hover {
    text-decoration: underline;
    
    // &::after,
    // &::before {
    //   display: block;
    // }
    &::after{
      display: block;
    }
  }
 
  
  &[data-side="bottom"] {
    &::after,
    &::before {
      bottom: auto;
      top: 110%;
    }
    
    &::after {
      transform: translate(-50%, 0);
    }
    
    &::before {
      transform: translate(-50%, -50%) rotate(225deg);
    }
  }
}

@media screen and (max-width:540px)
{
  .orgteam-frame{
        &:after {
      height:248px;
      width:280px;
      
    }
  }
  .orgteam-section{
    font-size: 20px;
  }
  
  .orgteam-frame img{
    height:240px;
    width:240px;
  }
}

@media screen and (max-width:350px)
{
  .orgteam-frame{
        &:after {
      height:223.2px;
      width:256px;
      
    }
  }
  .orgteam-section{
    font-size: 18px;
  }
  .orgteam-frame img{
    height:216px;
    width:216px;
  }
}