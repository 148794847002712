@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");


.WebTeam_wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.WebTeam_container {
    font-family: "Poppins",sans-serif;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 40px 0;
}

.WebTeam_imgBx{
  height: fit-content;
}

.WebTeam_imgBx img{
    object-fit: cover;
    height: 60%;
    width: 100%;
}
.WebTeam_container .WebTeam_card {
  position: relative;
  width: 291px;
  height: 288px;
  margin: 20px;
  overflow: hidden;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.WebTeam_container .WebTeam_card .WebTeam_content {
  position: absolute;
  bottom: -30px;
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  // z-index: 10;
  flex-direction: column;
  // backdrop-filter: blur(5px);
  // box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.1);
  // border: 1px solid rgba(255, 255, 255, 0.1);
  // border-radius: 15px;
  // transition: bottom 0.5s;
  // transition-delay: 0.65s;
  overflow: visible;
}
// .WebTeam_container .WebTeam_card:hover .WebTeam_content {
//   bottom: 0;
//   transition-delay: 0s;
// }
.WebTeam_container .WebTeam_card .WebTeam_content .WebTeam_contentBx h3 {
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  margin: 5px 0 11px;
  line-height: 0.5em;
  // transition: 0.5s;
  // transition-delay: 0.6s;
  opacity: 1;
  // transform: translateY(-20px);
  overflow:visible
}

.WebTeam_container .WebTeam_card .WebTeam_content .WebTeam_contentBx h2 {
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  margin: 2px 0 20px;
  line-height: 1.1em;
  // transition: 0.5s;
  // transition-delay: 0.6s;
  opacity: 1;
  // transform: translateY(-20px);
  overflow:visible
}
.WebTeam_container .WebTeam_card:hover .WebTeam_content .WebTeam_contentBx h3 {
  opacity: 1;
  // transform: translateY(0);
}
.WebTeam_container .WebTeam_card .WebTeam_content .WebTeam_contentBx h3 span {
  font-size: 12px;
  font-weight: 300;
  text-transform: initial;
}

// .WebTeam_container .WebTeam_card:hover .WebTeam_content .WebTeam_contentBx h2 {
//   opacity: 1;
//   // transform: translateY(0);
// }
.WebTeam_container .WebTeam_card .WebTeam_content .WebTeam_contentBx h2 span {
  font-size: 12px;
  font-weight: 300;
  text-transform: initial;
}
.WebTeam_container .WebTeam_card .WebTeam_content .WebTeam_sci {
  position: relative;
  bottom: 10px;
  display: flex;
  overflow: visible;
}
.WebTeam_container .WebTeam_card .WebTeam_content .WebTeam_sci li:nth-child(1) {
  list-style: none;
  margin: 0 10px;
  // transform: translateY(40px);
  // transition: 0.5s;
  opacity: 1;
  // transition-delay: calc(0.2s * 1);
}
.WebTeam_container .WebTeam_card .WebTeam_content .WebTeam_sci li:nth-child(2) {
  list-style: none;
  margin: 0 10px;
  // transform: translateY(40px);
  // transition: 0.5s;
  opacity: 1;
  // transition-delay: calc(0.2s * 2);
}
.WebTeam_container .WebTeam_card .WebTeam_content .WebTeam_sci li:nth-child(3) {
  list-style: none;
  margin: 0 10px;
  transform: translateY(40px);
  transition: 0.5s;
  opacity: 0;
  transition-delay: calc(0.2s * 3);
}

.WebTeam_container .WebTeam_card:hover .WebTeam_content .WebTeam_sci li {
  transform: translateY(0);
  opacity: 1;
}
.WebTeam_container .WebTeam_card .WebTeam_content .WebTeam_sci li a {
  color: white;
  font-size: 24px;
}
