@mixin white-gradient {
	background: linear-gradient(to right, var(--bgColor1) 0%,rgba(255,255,255,0) 40%);
}

$animationSpeed: 16s;

// Animation
@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
}
// Styling
.Sponsors_slider{
	// background: rgb(0, 0, 0);
	background: transparent;
	// box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
	height: 120px;
	margin: auto;
	overflow:hidden;
	position: relative;
	width: 100vw;
	margin-bottom:5rem;
	
	&::before,
	&::after {
		@include white-gradient;
		content: "";
		height: 100px;
		position: absolute;
		width: 200px;
		z-index: 2;
	}
	
	&::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		left: 0;
		top: 0;
	}
	
	.Sponsors_slide-track {
		animation: scroll $animationSpeed linear infinite;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: calc(370px * 5);

		.Sponsors_slide{
			margin : 0;
			img {
				mix-blend-mode: difference;
			}
		}
	}
	
	//.Sponsors_slide {
	//	height: 100px;
	//	width: 250px;
	//}
}