// @import url('https://fonts.googleapis.com/css2?family=Tilt+Warp&display=swap');

// .heading_main {
//     position: relative;

//     height: 30vh;

//     .heading_main_title {
//         width: 100vw;
//         height: 100%;
//         // background-color: rgb(0, 0, 0);
//         background-image: linear-gradient(rgba(0, 0, 0, 0.9), black, rgba(0, 0, 0, 0.9));
//         font-size: 7rem;
//         color: white;
//         mix-blend-mode: multiply;
//         font-family: 'Tilt Warp', cursive;
//         text-align: center;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//     }

//     video {
//         object-fit: cover;
//         width: 100%;
//         height: 100%;
//         position: absolute;
//         // z-index: -1;
//         top: 0;
//         left: 0;

//     }

// }

// @media screen and (max-width : 1080px) {
//     .heading_main {
//         height: 15vh;
//         .heading_main_title {
//             font-size: 3.5rem;
//             padding : 0 5px;
//             // height : 20vh;
//         }

//     }

// }
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@700&display=swap");


.heading-section {
  width: 100wh;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.animate-charcter {
  // font-family: "Libre Baskerville", serif;
  font-family: 'Orbitron', sans-serif;
  text-transform: uppercase;
  background-image: radial-gradient(#aed9ee 25%, #5990b9 50%, #091335 75%, #000000 100%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 5rem;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

@media  screen and (max-width:576px) {
  .animate-charcter{
    font-size:2.5rem !important;
  }
  .heading-section{
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}