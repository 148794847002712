.speaker-hero-section {
  width: 100vw;
  background-image: radial-gradient(circle, rgb(9, 19, 53, 0) 0%, var(--bgColor1) 100%), url("./speaker_bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;

}

.fadein-div {
  background-image: linear-gradient(to bottom, rgb(9, 19, 53, 1), rgb(9, 19, 53, 0), rgb(9, 19, 53, 0), rgb(9, 19, 53, 0), rgb(9, 19, 53, 1));
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.speaker-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  margin: 0 auto;
  width: 50vw;

}

.speaker-card {
  position: relative;
  height: 22rem;
  // background-color: lightGrey;
  overflow-y: hidden;
  box-shadow: 0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  // margin: auto;
}

.speaker-card .speaker-card-title {
  overflow-x: visible;
  margin: 0;
  display: block;
  background-color: #aed9ee;
  padding: .70rem;
  transform: translate3d(0, -100%, 0);
  transition: transform 300ms;
  border-radius: .25rem .25rem 0 0;
  font-family: 'Bebas Neue';

  h3 {
    letter-spacing: 3px;
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }
}

.speaker-card-title h3 {
  margin: 0;
  font-size: 1rem;
  overflow-x: visible;
}

.speaker-card-title p {
  margin: 0;
  font-size: .80rem;
}

.speaker-card a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.speaker-card a:hover~.speaker-card-overlay,
.speaker-card a:focus~.speaker-card-overlay {
  transform: translate3d(0, 0, 0);
}

.speaker-card a:hover~.speaker-card-overlay .speaker-card-title,
.speaker-card a:focus~.speaker-card-overlay .speaker-card-title {
  transform: translate3d(0, 0, 0);
}

.speaker-card a:hover~.speaker-card-overlay .speaker-card__content,
.speaker-card a:focus~.speaker-card-overlay .speaker-card__content {
  opacity: 1;
}

.speaker-card-img {
  width: 100%;
  height: 100%;
  // height: fit-content;
  display: block;
  object-fit: cover;
}

.speaker-card-overlay {
  overflow-x: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  transition: transform 300ms;
  background: linear-gradient(black, rgba(0, 0, 0, 0.604));
  transform: translate3d(0, 100%, 0);

  .speaker-card__content {
    p {
      color: white;
    }
  }
}

.speaker-card__content {
  overflow-x: visible;
  flex-grow: 1;
  padding: 1rem;
  opacity: 0;
  transition: opacity 500ms 100ms;

}

.speaker-card__content p {
  margin: 0;
  text-align: justify;
  font-family: 'Genos', sans-serif;
  font-size: 18px;
  line-height: 17px;
  overflow: hidden;

}



@media only screen and (max-width:1080px) {
  .speaker-card-container {
    display: grid;
    grid-template-columns: auto;
    width: 90vw;
  }

  .speaker-card__content p {
    margin: 0;
    text-align: justify;
    font-family: 'Genos', sans-serif;
    font-size: 16px;
    line-height: 15px;
    overflow: hidden;

  }

}