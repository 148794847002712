.footer {
    // background: rgb(0, 0, 0);
    background-image: linear-gradient(to bottom, var(--bgColor1) , rgb(5, 0, 29));
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 10vh;
    width: 100%;
    overflow: hidden;
    // height: 120px;
    padding: 0.5rem;

    .copyright {
        margin: 5px 21rem 0 0;
        width: auto;
        font-size: 17px;
        font-family: 'Genos', sans-serif;
        color: white;
    }

    // .footer_spacer{
    //     width : 40rem;
    // }
    .socials {
        margin: 10px 0 7px 15rem;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        .fa-2xl{
            font-size: 1.45em;
        }
    }
  

    .icon {
        margin: 0 9px;
        color: white;
        height: 8vh;
        display: flex;
        align-items: center;

        &:hover {
            color: #74b9ff;
        }
    }


}

@media screen and (max-width: 1080px) {

    .footer {
        height: 18vh;
        padding: 0.60rem;
        flex-direction: column;

        .copyright {
            margin: 5px auto;
            width: auto;
            font-size: 0.8rem;
            text-align: center;
        }

        .socials {
            margin: 10px 0;
            .fa-2xl{
                font-size: 1.45em;
            }
        }

    }

}