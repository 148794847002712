.events-section {
  width:100vw;
  background-image:radial-gradient(circle,rgb(9, 19, 53,0) 0%, var(--bgColor1) 100%),url("https://static.vecteezy.com/system/resources/previews/001/542/515/original/neon-light-gate-background-free-vector.jpg");
  background-size:cover;
  background-repeat:no-repeat;
  background-position:center center;
  background-attachment:fixed;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
  margin-top: 7rem;
}
.fadein-div{
  background-image: linear-gradient(to bottom,rgb(9, 19, 53,1),rgb(9, 19, 53,0),rgb(9, 19, 53,0),rgb(9, 19, 53,0) ,rgb(9, 19, 53,1));
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card-container{
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  align-items:center;
  max-width: 1180px;
  color: white;
}
.card {
  overflow-y:visible;
  margin:2rem;
  box-shadow: 5px 5px 5px #aed9ee;
  // background-image: url("https://tryst-bucket.s3.amazonaws.com/events/posters/CEF___3.webp");
  background-size: 100% 100%;
  background-position: center center;
  border-radius: 10px;
  transition: 200ms ease;
  opacity: 0.8;
  padding: 17.6rem 0 0;
  // max-width: 30ch;
  width: 310px;
  height: 400px;
  overflow: hidden;
  .card-content {
    padding: 40px 20px;
    background: linear-gradient(
      hsl(0 0% 0% / 0),
      hsl(20 0% 0% / 0.3) 20%,
      hsl(0 0% 0% / 1)
    );
    transform: translateY(58%);
    transition: transform 300ms ease-in-out;
  }
  .card-title {
    position: relative;
    width: fit-content;
    backdrop-filter:blur(2px);
    margin-bottom: 0.5rem;
    font-family: "Genos";
  }
  .card-title::after {
    content: "";
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background: rgba(171,161, 52);
    position: absolute;
    transform: scalex(0);
    transform-origin: left;
    transition: transform 500ms ease-in-out;
    backdrop-filter:blur(0px);
  }
  .card-bttn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(23, 34, 166, 0.91) ;
    padding: 5px;
    font-family: 'Genos', sans-serif;
    font-size : 17px;
    font-weight : bold;
    width: fit-content;
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 2px 2px 2px #aed9ee;
  }
  .card-text {
    color: rgb(255 255 255 / 0.8);
  }
}

.card:hover {
  transform: scale(1.06);
  opacity: 1;
  box-shadow: 10px 10px 10px white;
}

.card:hover .card-title::after {
  transform: scalex(1);
  
  
}

.card:hover .card-content {
  transform: translateY(0);
  // backdrop-filter:blur(2px);
}